import Vue from 'vue';
import flatten from 'lodash/flatten';

import tasks from './tasks';

const dashboard = {
  namespaced: true,
  state: {
    data: {
      templateConfiguration: null,
      sections: [],
      todoList: [],
      formTodoLink: [],
      formsV2ToDo: [],
      templateFeatures: null,
      finishedTasks: []
    },
    errors: {},
    loadingSections: false,
    loading: false,
    siblings: []
  },
  getters: {
    dashboardData(state) {
      return state.data;
    },
    dashboardErrors(state) {
      return state.errors;
    },
    menu(state) {
      return state.data ? state.data.sections : null;
    },
    siblings(state) {
      return state.siblings;
    },
    formsV2Enabled(state, getters) {
      return getters.dashboardData.enableNewForms;
    },
    onboardeeId(state, getters) {
      return getters.dashboardData.onboardingId;
    },
    dashboardSections(state, getters) {
      return getters.dashboardData.sections;
    },
    dashboardHeader(state, getters) {
      return getters.dashboardData.templateConfiguration;
    },
    finishedTasks(state, getters) {
      return getters.dashboardData.finishedTasks;
    },
    isOnboarding(state, getters) {
      return getters.dashboardData.templateConfiguration.endUserType == 'Onboardee';
    }
  },
  mutations: {
    addToTodolist(state, payload) {
      state.data.todoList = state.data.todoList.map((e) => {
        if (e.routeDictionary && e.routeDictionary.id) {
          const newElem = payload.find((elem) => {
            if (elem.routeDictionary && elem.routeDictionary.id) {
              return elem.routeDictionary.id === e.routeDictionary.id;
            } else {
              return false;
            }
          });
          return newElem ? newElem : e;
        } else {
          return e;
        }
      });
      const newTodos = [...state.data.todoList, ...payload].filter(
        (todo, index, todos) =>
          todos.findIndex((todoMatch) => JSON.stringify(todoMatch) === JSON.stringify(todo)) === index
      );
      state.data.todoList = newTodos.filter((e) => !e.isFinished);
      state.data.finishedTasks = state.data.finishedTasks
        .concat(newTodos.filter((e) => typeof e.isFinished === 'boolean' && e.isFinished === true))
        .filter(
          (todo, index, todos) =>
            todos.findIndex((todoMatch) => JSON.stringify(todoMatch) === JSON.stringify(todo)) === index
        );
    },
    clearTodoList(state) {
      state.data.todoList = [];
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setErrors(state, payload) {
      state.errors = payload;
    },
    setLoadingSections(state, payload) {
      state.loadingSections = payload;
    },
    setNavigationLearningPage(state, payload) {},
    setOnboardeeInformation(state, payload) {
      state.data = { ...state.data, ...payload };
    },
    setTemplateConfiguration(state, payload) {
      state.data.templateConfiguration = payload;
    },
    setTemplateFeatures(state, payload) {
      state.data.templateFeatures = payload;
    },
    setDashboardSections(state, payload) {
      state.data.sections = payload;
    },
    setDashboardForms(state, payload) {
      state.data.formTodoLink = payload.formTodoLink;
      state.data.formsV2ToDo = payload.formsV2ToDo;
    },
    toggleMenuDropdown(state, payload) {
      const sections = state.data.sections.map((i) => {
        if (i.id === payload) {
          i.opened = !i.opened;

          if (!i.opened && i.items && i.items.length) {
            i.items.map((j) => {
              j.opened = false;

              if (j.items && j.items.length) {
                j.items.map((k) => {
                  k.opened = false;
                });
              }
            });
          }
        }
        return i;
      });
      state.data = { ...state.data, sections };
    },
    toggleSubMenuDropdown(state, { itemId, subItemId }) {
      const sections = state.data.sections.map((i) => {
        if (i.id === itemId) {
          i.items.map((j) => {
            if (j.id === subItemId) {
              j.opened = !j.opened;

              if (!j.opened && j.items && j.items.length) {
                j.items.map((k) => {
                  k.opened = false;
                });
              }
            }
          });
        }
        return i;
      });
      state.data = { ...state.data, sections };
    },
    toggleSubSubMenuDropdown(state, { itemId, subItemId, subSubItemId }) {
      const sections = state.data.sections.map((i) => {
        if (i.id === itemId) {
          i.items.map((j) => {
            if (j.id === subItemId) {
              j.items.map((k) => {
                if (k.id === subSubItemId) {
                  k.opened = !k.opened;
                }
              });
            }
          });
        }
        return i;
      });
      state.data = { ...state.data, sections };
    }
  },
  actions: {
    async getDashboard({ dispatch }) {
      await dispatch('getOnboardeeInformation').catch((err) => err);
      await dispatch('getTemplateConfiguration').catch((err) => err);
      await dispatch('getDashboardForms').catch((err) => err);
      await dispatch('getOnboardeeTodoList').catch((err) => err);
      await dispatch('getTemplateFeatures').catch((err) => err);
      await dispatch('postDashboardVisit').catch((err) => err);
    },
    async getTodoData({ dispatch }) {
      await dispatch('getDashboardForms').catch((err) => err);
      await dispatch('getOnboardeeTodoList').catch((err) => err);
    },
    getOnboardeeInformation({ commit }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/v2/onboardee-information?utcMinutesOffset=${new Date().getTimezoneOffset() * -1}`)
          .then((response) => {
            commit('setLoading', true);
            if (response.data.calendarDays) {
              response.data.isWorking = response.data.calendarDays <= 0;
            }

            const originalWorkingDaysValue = response.data.isWorking
              ? response.data.calendarDays
              : 0 - response.data.calendarDays;
            commit('setOnboardeeInformation', response.data);
            commit('setLoading', false);
            resolve();
          })
          .catch((error) => {
            commit('setLoading', false);
            reject(error);
          });
      });
    },
    getOnboardeeTerminologies({ commit }) {
      return new Promise((resolve, reject) => {
        const user = JSON.parse(window.localStorage.getItem('onboarding_user'));

        Vue.prototype.$http
          .get(`/v2/terminologies?languageId=${user.languageId}`)
          .then((response) => {
            if (response.data) {
              this.dispatch('settings/setTerminologyModels', response.data.data);
            }
            resolve();
          })
          .catch((err) => {
            commit('setErrors', { terminologies: err });
            reject(err);
          });
      });
    },
    getTemplateConfiguration({ commit }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/v2/template-configuration')
          .then((response) => {
            commit('setLoading', true);
            commit('setTemplateConfiguration', response.data);
            commit('setLoading', false);
            resolve();
          })
          .catch((err) => {
            commit('setLoading', false);
            commit('setErrors', { templateConfiguration: err });
            reject(err);
          });
      });
    },
    getTemplateFeatures({ commit }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/v2/template-features')
          .then((response) => {
            commit('setTemplateFeatures', response.data);
            resolve();
          })
          .catch((err) => {
            commit('setErrors', { templateFeatures: err });
            reject(err);
          });
      });
    },
    getOnboardeeTodoList({ commit }) {
      return new Promise((resolve, reject) => {
        const user = JSON.parse(window.localStorage.getItem('onboarding_user'));
        let adminFilters = '';
        if (window.localStorage.admin_filters) {
          adminFilters = `&filterItems=${window.localStorage.admin_filters}`;
        }
        Vue.prototype.$http
          .get(`/v2/to-dos?languageId=${user.languageId}${adminFilters}`)
          .then((response) => {
            response.data = response.data.todoList.map((i) => {
              switch (i.type) {
                case 1:
                  i.route = {
                    name: 'companyPageContent',
                    params: { overview: i.routeDictionary.sectionId, id: i.routeDictionary.id }
                  };
                  break;
                case 2:
                case 6:
                case 7:
                case 8:
                  i.route = `#${i.routeDictionary.id}`;
                  break;
                case 3:
                  i.route = {
                    name: 'introduction',
                    params: {
                      id: i.routeDictionary.sectionId
                    }
                  };
                  break;
                case 4:
                  i.route = {
                    name: 'pulseCheckContent',
                    params: {
                      overview: i.routeDictionary.sectionId,
                      id: i.routeDictionary.id
                    }
                  };
                  break;
                case 5:
                  i.route = {
                    name: 'quizContent',
                    params: {
                      overview: i.routeDictionary.sectionId,
                      id: i.routeDictionary.id
                    }
                  };
                  break;
                case 9:
                  i.route = {
                    name: 'legacyForm',
                    params: {
                      id: i.routeDictionary.id
                    }
                  };
                  break;
              }
              return i;
            });
            commit('addToTodolist', response.data);
            resolve();
          })
          .catch((err) => {
            commit('setErrors', { todoList: err });
            reject(err);
          });
      });
    },
    getDashboardSections({ commit }) {
      return new Promise((resolve, reject) => {
        commit('setLoadingSections', true);
        const user = JSON.parse(window.localStorage.getItem('onboarding_user'));
        let adminFilters = '';
        if (window.localStorage.admin_filters) {
          adminFilters = `&filterItems=${window.localStorage.admin_filters}`;
        }
        Vue.prototype.$http
          .get(`/v2/dashboard/sections?languageId=${user.languageId}${adminFilters}`)
          .then((response) => {
            let learningPages = [];
            response.data = response.data.data
              ? response.data.data
                  .map((item) => {
                    switch (item.type) {
                      case 1:
                        item.component = 'dashboardCompanyPage';
                        item.icon = 'business';
                        if (item.displayType !== 2) {
                          item.items = item.items.filter((i) => i.isOnDashboard);
                        }
                        if (item.items) {
                          item.items.forEach((i) => {
                            i.navigate = true;
                            i.type = 1;
                            i.items = item.items.filter((page) => page.parentId === i.id);
                            if (!i.items.length) {
                              if (i.formId) {
                                i.navigate = true;
                                i.link = {
                                  name: 'legacyForm',
                                  params: {
                                    id: i.formId
                                  }
                                };
                              } else if (i.sectionId) {
                                i.link = {
                                  name: 'companyPageContent',
                                  params: {
                                    overview: i.sectionId,
                                    id: i.id
                                  }
                                };
                              }
                            } else {
                              i.link = '';
                            }
                          });
                        }
                        break;
                      case 2:
                        item.component = 'dashboardDidYouKnow';
                        item.items.forEach((i) => {
                          i.navigate = true;
                          const limit = item.displayWidthType === 1 ? 180 : 300;
                          i.hasLongMessage = i.content.length > limit;
                          if (i.content.includes('&lt;') && i.content.includes('&gt;')) {
                            i.hasLongMessage = false;
                          }
                          if (i.content.length > limit) {
                            i.shortMessage = i.content.substring(0, limit - 10);
                            i.shortMessage =
                              i.shortMessage.substring(
                                0,
                                Math.min(i.shortMessage.length, i.shortMessage.lastIndexOf(' '))
                              ) + '...';
                          }
                        });
                        item.icon = 'lightbulb_outline';
                        break;
                      case 3:
                        item.component = 'dashboardIntroduction';
                        item.icon = 'record_voice_over';
                        break;
                      case 4:
                        item.component = 'dashboardPulseCheck';
                        item.icon = 'star_rate';
                        item.items = item.items
                          .filter((pulseCheck) => pulseCheck.isVisibleForUser)
                          .map((pulseCheck) => {
                            pulseCheck.link = {
                              name: 'pulseCheckContent',
                              params: {
                                overview: item.id,
                                id: pulseCheck.id
                              }
                            };
                            pulseCheck.navigate = true;
                            pulseCheck.title = pulseCheck.question;
                            return pulseCheck;
                          });
                        break;
                      case 5:
                        item.component = 'dashboardQuiz';
                        item.icon = 'question_answer';
                        item.topItems.forEach((i) => {
                          i.link = {
                            name: 'quizContent',
                            params: {
                              overview: item.id,
                              id: i.id
                            }
                          };
                          i.navigate = true;
                          i.title = i.quizTitle;
                          i.disabled = i.status === 2 || i.status === 4;
                        });
                        break;
                      case 6:
                        item.component = 'dashboardVideo';
                        item.icon = 'videocam';
                        if (item.movieLink.indexOf('youku') > -1) {
                          item.movieLink = `<iframe src='${item.movieLink}' frameborder=0 'allowfullscreen'></iframe>`;
                        } else {
                          item.movieLink = item.movieLink
                            .replace(/(width=")\d+("\W+height=")\d+/, '$1100%$2100%')
                            .replace('src="//', 'src="https://')
                            .replace('autoplay=1', 'autoplay=0')
                            .replace('<iframe', '<iframe class="embed-responsive-item"');
                        }
                        break;
                      case 7:
                        item.component = 'dashboardContacts';
                        item.icon = 'contacts';
                        break;
                      case 8:
                        item.component = 'dashboardContacts';
                        item.icon = 'rate_review';
                        break;
                      case 9:
                        item.component = 'dashboardForm';
                        item.icon = 'content_paste';
                        break;
                      case 10:
                        item.component = 'dashboardTask';
                        item.icon = 'content_paste';
                        break;
                      case 13:
                        learningPages = [];
                        learningPages = learningPages.concat(
                          item.learningPages.map((lp) => {
                            lp.link = {
                              name: 'learningPageContent',
                              params: {
                                module: item.id,
                                id: lp.id
                              }
                            };

                            if (lp.children && lp.children.length > 0) {
                              lp.navigate = false;
                              lp.opened = false;

                              lp.children.map((lpChild) => {
                                lpChild.link = {
                                  name: 'learningPageContent',
                                  params: {
                                    module: item.id,
                                    id: lpChild.id
                                  }
                                };

                                if (lpChild.children && lpChild.children.length > 0) {
                                  lpChild.navigate = false;
                                  lpChild.opened = false;

                                  lpChild.children.map((lpChildChild) => {
                                    lpChildChild.link = {
                                      name: 'learningPageContent',
                                      params: {
                                        module: item.id,
                                        id: lpChildChild.id
                                      }
                                    };
                                  });

                                  lpChild.items = lpChild.children;
                                } else {
                                  lpChild.navigate = true;
                                }
                              });

                              lp.items = lp.children;
                            } else {
                              lp.navigate = true;
                            }
                            return lp;
                          })
                        );

                        item.items = learningPages;
                        item.component = 'dashboardLearningPage';
                        item.icon = 'school';
                    }

                    if (item.component === 'dashboardQuiz') {
                      item.items = item.topItems;
                    }

                    if (
                      item.component === 'dashboardQuiz' ||
                      item.component === 'dashboardPulseCheck' ||
                      item.component === 'dashboardCompanyPage' ||
                      item.component === 'dashboardLearningPage'
                    ) {
                      item.hasDropdown = item.items && item.items.length;
                      item.items.forEach((i) => {
                        if (!i.title) {
                          i.title = i.question || i.label;
                        }
                      });
                    }
                    return item;
                  })
                  .filter((item) => {
                    if (
                      (item.component == 'dashboardTestimonials' || item.component == 'dashboardContacts') &&
                      !item.items.length
                    ) {
                      return false;
                    } else if (item.component == 'dashboardQuiz' && !item.topItems.length) {
                      return false;
                    }
                    return item;
                  })
              : [];

            this.dispatch('learningPages/setLearningPages', learningPages);
            this.dispatch('learningPages/setCompanyImages', flatten(learningPages.map((page) => page.images)));
            commit('setDashboardSections', response.data);
            commit('setLoadingSections', false);
            resolve();
          })
          .catch((err) => {
            commit('setErrors', { sections: err });
            commit('setLoadingSections', false);
            commit('setErrors', { sections: err });
            reject(err);
          });
      });
    },
    getDashboardForms({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        let adminFilters = '';
        if (window.localStorage.admin_filters) {
          adminFilters = `?filterItems=${window.localStorage.admin_filters}`;
        }
        const user = JSON.parse(window.localStorage.getItem('onboarding_user'));
        Vue.prototype.$http
          .get(`/v2/dashboard/forms${adminFilters}?languageId=${user.languageId}`)
          .then((response) => {
            commit('setDashboardForms', response.data);
            resolve();
          })
          .catch((err) => {
            commit('setErrors', { forms: err });
            reject(err);
          });
      });
    },
    postDashboardVisit({ commit }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/v2/dashboard/visits')
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    toggleMenuDropdown({ commit }, payload) {
      commit('toggleMenuDropdown', payload);
    },
    toggleSubMenuDropdown({ commit }, payload) {
      commit('toggleSubMenuDropdown', payload);
    },
    toggleSubSubMenuDropdown({ commit }, payload) {
      commit('toggleSubSubMenuDropdown', payload);
    }
  }
};

export default dashboard;
