import Vue from 'vue';
import store from '../store';

const { VUE_APP_TASK_API_URL, VUE_APP_TASK_API_URL_V2 } = process.env;

const mapTaskAssigneeBE = (taskAssigneeBE) => {
  const translations = JSON.parse(taskAssigneeBE.taskDefinition.translations).map((taskTranslation) => {
    // Nancy foolproof
    return {
      language: taskTranslation.Language || taskTranslation.language,
      title: taskTranslation.Title || taskTranslation.title,
      description: taskTranslation.Description || taskTranslation.description
    };
  });

  const dueDate = new Date(taskAssigneeBE.onboardee.startDate);
  dueDate.setDate(dueDate.getDate() + taskAssigneeBE.taskDefinition.dueDateOffset);

  const taskAssignee = {
    isFinished: taskAssigneeBE.status === 0 ? false : true,
    translations,
    onboardeeId: taskAssigneeBE.onboardee.userId,
    dueDate: dueDate.toString(),
    taskAssignmentId: taskAssigneeBE.id,
    taskDefinitionId: taskAssigneeBE.taskDefinitionId,
    status: taskAssigneeBE.status,
    taskOwnerMatchId: taskAssigneeBE.taskDefinition.ownerMatchId,
    assignedToUserId: taskAssigneeBE.assignedToUserId,
    commentsCount: taskAssigneeBE.commentsCount
  };
  return taskAssignee;
};

const tasks = {
  namespaced: true,
  state: {
    onboardeeTasks: [],
    users: []
  },
  getters: {
    onboardeeTasks: (state) => {
      return state.onboardeeTasks;
    },
    users: (state) => {
      return state.users;
    }
  },
  mutations: {
    setOnboardeeTasks(state, onboardeeTasks) {
      state.onboardeeTasks = onboardeeTasks;
    },
    setTaskStatus(state, { taskAssignmentId, status }) {
      state.onboardeeTasks = state.onboardeeTasks.map((task) => {
        if (task.id === taskAssignmentId) {
          task.status = status;
          if (status === 1) {
            task.isFinished = true;
          } else {
            task.isFinished = false;
          }
        }
        return task;
      });
    },
    setUsers(state, users) {
      users = users.filter((user) => !state.users.find((users) => users.id === user.id));
      state.users = [...state.users, ...users];
    }
  },
  actions: {
    getUsers: ({ commit, state }, userIds) => {
      return new Promise((resolve, reject) => {
        const user = store.getters['auth/user'];
        userIds = [...new Set(userIds)];
        let userIdsString = '';
        userIds.forEach((userId) => {
          userIdsString += `UserId=${userId}&`;
        });
        if (window.localStorage.getItem('onboarding_token')) {
          Vue.prototype.$http
            .get(`${VUE_APP_TASK_API_URL}/Users?${userIdsString}`, {
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem('onboarding_token')}`
              }
            })
            .then(
              (response) => {
                commit('setUsers', response.data);
                resolve(response.data);
              },
              (error) => {
                reject(error);
              }
            );
        } else {
          Vue.prototype.$http.get(`${VUE_APP_TASK_API_URL}/Users?${userIdsString}`).then(
            (response) => {
              commit('setUsers', response.data);
              resolve(response.data);
            },
            (error) => {
              reject(error);
            }
          );
        }
      });
    },
    getOnboardeeTasks: ({ commit, state }) => {
      return new Promise((resolve, reject) => {
        const user = store.getters['auth/user'];
        const defaultLanguage = store.getters['settings/defaultLanguage'];
        if (window.localStorage.getItem('onboarding_token')) {
          Vue.prototype.$http
            .get(
              `${VUE_APP_TASK_API_URL_V2}/TasksAssigned?companyId=${user.companyId}&includeTaskDefinition=true&customTaskInclusionState=0&includeDeleted=false&includeAssignedTo=true&includeOnboardee=true&includeCommentCount=true&includeCount=true&PageSize=9999&assignedToUserId=${user.id}&taskDefinitionTypes=Onboardee`,
              {
                headers: {
                  Authorization: `Bearer ${window.localStorage.getItem('onboarding_token')}`
                }
              }
            )
            .then(
              (response) => {
                const locale = JSON.parse(window.localStorage.getItem('onboarding_user')).language;

                response.data.data = response.data.data
                  .filter((taskAssigneeBE) => taskAssigneeBE.taskDefinition)
                  .map((taskAssigneeBE) => mapTaskAssigneeBE(taskAssigneeBE));

                const tasksv2 = response.data.data
                  .map((task) => {
                    const defaultTranslation = task.translations.find(
                      (translation) => translation.language === defaultLanguage
                    );
                    let translationMatch = task.translations.find((translation) => translation.language === locale);
                    if (!translationMatch) {
                      translationMatch = defaultTranslation;
                    }

                    if (!defaultTranslation) {
                      return null;
                    }
                    return {
                      title: translationMatch.title === '' ? defaultTranslation.title : translationMatch.title,
                      name: translationMatch.title,
                      description:
                        translationMatch.description === ''
                          ? defaultTranslation.description
                          : translationMatch.description,
                      type: 10,
                      id: task.taskAssignmentId,
                      taskDefinitionId: task.taskDefinitionId,
                      route: '/tasks',
                      dueDate: new Date(task.dueDate),
                      isFinished: task.status === 0 ? false : true,
                      status: task.status,
                      commentsCount: task.commentsCount
                    };
                  })
                  .filter((task) => task !== null);
                commit('setOnboardeeTasks', tasksv2);
                resolve();
              },
              (error) => {
                console.error('ISSUE GETTING TASKS', error);
                reject(error);
              }
            );
        } else {
          Vue.prototype.$http
            .get(
              `${VUE_APP_TASK_API_URL_V2}/TasksAssigned?companyId=${user.companyId}&includeTaskDefinition=true&customTaskInclusionState=0&includeDeleted=false&includeAssignedTo=true&includeOnboardee=true&includeCommentCount=true&includeCount=true&PageSize=9999&assignedToUserId=${user.id}&taskDefinitionTypes=Onboardee`
            )
            .then(
              (response) => {
                const locale = JSON.parse(window.localStorage.getItem('onboarding_user')).language;

                response.data.data = response.data.data
                  .filter((taskAssigneeBE) => taskAssigneeBE.taskDefinition)
                  .map((taskAssigneeBE) => mapTaskAssigneeBE(taskAssigneeBE));

                const tasksv2 = response.data.data
                  .map((task) => {
                    const defaultTranslation = task.translations.find(
                      (translation) => translation.language === defaultLanguage
                    );
                    let translationMatch = task.translations.find((translation) => translation.language === locale);
                    if (!translationMatch) {
                      translationMatch = defaultTranslation;
                    }

                    if (!defaultTranslation) {
                      return null;
                    }
                    return {
                      title: translationMatch.title === '' ? defaultTranslation.title : translationMatch.title,
                      name: translationMatch.title,
                      description:
                        translationMatch.description === ''
                          ? defaultTranslation.description
                          : translationMatch.description,
                      type: 10,
                      id: task.taskAssignmentId,
                      taskDefinitionId: task.taskDefinitionId,
                      route: '/tasks',
                      dueDate: new Date(task.dueDate),
                      isFinished: task.status === 0 ? false : true,
                      status: task.status,
                      commentsCount: task.commentsCount
                    };
                  })
                  .filter((task) => task !== null);
                commit('setOnboardeeTasks', tasksv2);
                resolve();
              },
              (error) => {
                console.error('ISSUE GETTING TASKS', error);
                reject(error);
              }
            );
        }
      });
    },
    completeTask: ({ commit, state }, taskAssignmentId) => {
      return new Promise((resolve, reject) => {
        if (window.localStorage.getItem('onboarding_token')) {
          Vue.prototype.$http
            .put(`${VUE_APP_TASK_API_URL}/TasksAssigned/${taskAssignmentId}?Status=${1}`, null, {
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem('onboarding_token')}`
              }
            })
            .then(
              (response) => {
                resolve();
              },
              (error) => {
                reject(error);
              }
            );
        } else {
          Vue.prototype.$http.put(`${VUE_APP_TASK_API_URL}/TasksAssigned/${taskAssignmentId}?Status=${1}`, null).then(
            (response) => {
              resolve();
            },
            (error) => {
              reject(error);
            }
          );
        }
      });
    },
    uncompleteTask: ({ commit, state }, taskAssignmentId) => {
      return new Promise((resolve, reject) => {
        if (window.localStorage.getItem('onboarding_token')) {
          Vue.prototype.$http
            .put(`${VUE_APP_TASK_API_URL}/TasksAssigned/${taskAssignmentId}?Status=${0}`, null, {
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem('onboarding_token')}`
              }
            })
            .then(
              (response) => {
                resolve();
              },
              (error) => {
                reject(error);
              }
            );
        } else {
          Vue.prototype.$http.put(`${VUE_APP_TASK_API_URL}/TasksAssigned/${taskAssignmentId}?Status=${0}`, null).then(
            (response) => {
              resolve();
            },
            (error) => {
              reject(error);
            }
          );
        }
      });
    },
    getTaskCommentsById: ({ commit, state }, payload) => {
      return new Promise((resolve, reject) => {
        if (window.localStorage.getItem('onboarding_token')) {
          Vue.prototype.$http
            .get(
              `${VUE_APP_TASK_API_URL}/TasksAssigned/${payload.taskAssignmentId}/comments?PageSize=${payload.pageSize}&PageNumber=${payload.pageNumber}&OrderBy=createdAt&Ascending=false`,
              {
                headers: {
                  Authorization: `Bearer ${window.localStorage.getItem('onboarding_token')}`
                }
              }
            )
            .then(
              (response) => {
                resolve(response.data);
              },
              (error) => {
                reject(error);
              }
            );
        } else {
          Vue.prototype.$http
            .get(
              `${VUE_APP_TASK_API_URL}/TasksAssigned/${payload.taskAssignmentId}/comments?PageSize=${payload.pageSize}&PageNumber=${payload.pageNumber}&OrderBy=createdAt&Ascending=false`
            )
            .then(
              (response) => {
                resolve(response.data);
              },
              (error) => {
                reject(error);
              }
            );
        }
      });
    },
    postTaskComments: ({ commit, state }, payload) => {
      return new Promise((resolve, reject) => {
        if (window.localStorage.getItem('onboarding_token')) {
          Vue.prototype.$http
            .post(
              `${VUE_APP_TASK_API_URL}/TasksAssigned/${payload.taskAssignmentId}/comments`,
              {
                message: payload.message
              },
              {
                headers: {
                  Authorization: `Bearer ${window.localStorage.getItem('onboarding_token')}`
                }
              }
            )
            .then(
              (response) => {
                resolve();
              },
              (error) => {
                reject(error);
              }
            );
        } else {
          Vue.prototype.$http
            .post(`${VUE_APP_TASK_API_URL}/TasksAssigned/${payload.taskAssignmentId}/comments`, {
              message: payload.message
            })
            .then(
              (response) => {
                resolve();
              },
              (error) => {
                reject(error);
              }
            );
        }
      });
    },
    getFiles: ({ commit, state }, taskDefinitionId) => {
      return new Promise((resolve, reject) => {
        if (window.localStorage.getItem('onboarding_token')) {
          Vue.prototype.$http
            .get(`${VUE_APP_TASK_API_URL}/TasksDefinitions/${taskDefinitionId}/files`, {
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem('onboarding_token')}`
              }
            })
            .then(
              (response) => {
                resolve(response.data);
              },
              (error) => {
                reject(error);
              }
            );
        } else {
          Vue.prototype.$http.get(`${VUE_APP_TASK_API_URL}/TasksDefinitions/${taskDefinitionId}/files`).then(
            (response) => {
              resolve(response.data);
            },
            (error) => {
              reject(error);
            }
          );
        }
      });
    },
    getFileById: ({ commit, state }, fileReferenceId) => {
      return new Promise((resolve, reject) => {
        const headers = {
          headers: {
            responseType: 'blob'
          }
        };
        if (window.localStorage.getItem('onboarding_token')) {
          headers.headers.Authorization = `Bearer ${window.localStorage.getItem('onboarding_token')}`;
        }

        fetch(`${VUE_APP_TASK_API_URL}/UploadedFiles/${fileReferenceId}`, headers).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      });
    }
  }
};

export default tasks;
