const { VUE_APP_NOTIFICATION_API_URL } = process.env;

export const getVapidDetails = () => {
  let token = window.localStorage.getItem('onboarding_token');
  return fetch(`${VUE_APP_NOTIFICATION_API_URL}/subscription/get-vapid-details`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const upsertSubscription = async (subscription) => {
  const payload = {
    endpoint: subscription.endpoint,
    p256dh: arrayBufferToBase64(subscription.getKey('p256dh')),
    auth: arrayBufferToBase64(subscription.getKey('auth')),
    userId: JSON.parse(localStorage.getItem('onboarding_user')).id,
    companyId: JSON.parse(localStorage.getItem('onboarding_user')).companyId,
    templateId: JSON.parse(localStorage.getItem('templateId'))
  };
  let token = window.localStorage.getItem('onboarding_token');
  return fetch(`${VUE_APP_NOTIFICATION_API_URL}/subscription/subscriptions`, {
    body: JSON.stringify(payload),
    method: 'post',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });
};

function arrayBufferToBase64(buffer) {
  var binary = '';
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}
